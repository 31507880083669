<template>
  <div class="details_trips">
    <!-- head section -->
    <h2 class="max-width my-14 my-5 primary--text">{{ $t("tripDetails") }}</h2>

    <!-- content -->
    <div class="max-width">
      <v-row>
        <v-col cols="12" md="6">
          <div class="map-cover w-100 h-100">
            <GmapMap
              :center="{ lat: 10, lng: 10 }"
              :zoom="10"
              style="width: 100%; height: 500px"
            >
            </GmapMap>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div>
            <span class="d-block font-30 font-500 primary--text"
              >{{ $t("trip") }} {{ $t("from") }}<span class="font-weight-black" > {{ item.origin_country }}</span>
              {{ $t("to") }} <span class="font-weight-black" >{{ item.destination_country }}</span></span
            >
          </div>

          <!-- img & title -->
          <v-row class="my-4">
            <v-col cols="12">
              <div
                class="
                  
                  d-flex
                  align-center
                  justify-space-around
                "
              >
                <div>
                  <span>{{ $t("from") }} </span>
                  <h3>{{ item.origin_city }}</h3>
                </div>
                <div>
                  <img
                    :src="require('@/assets/images/global/airpot.png')"
                    width="25"
                    height="25"
                  />
                </div>
                <div>
                  <span>{{ $t("to") }} </span>
                  <h3>{{ item.destination_city }}</h3>
                </div>
              </div>
            </v-col>
            <v-col cols="6">
              <div class="w-100 text-center">
                <img
                  class="object-cover mx-auto radius-5"
                  :width="isMobile ? '150px' : '250px'"
                  :height="isMobile ? '150px' :'250px'"
                  :src="item.bringer_image"
                  alt=""
                />
              </div>
            </v-col>
            <v-col
              cols="6"
              md="6"
              align=""
              style="
                height: 250px;
                display: flex;
                flex-direction: column;
                align-content: space-between;
                justify-content: space-between;
              "
            >
              <div class="d-flex">
                <span class="grey--text font-25">{{$t('name')}} :</span>
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.bringer_name }}
                </span>
              </div>

              <!-- rate -->
              <v-rating
                :value="+item.rate"
                readonly
                background-color="darkgray"
                empty-icon="mdi-star"
                color="yellow"
                size="14"
              ></v-rating>
              <div class="d-flex">
                <span class="grey--text font-25">{{ $t("date") }} :</span>
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.expected_date }}
                </span>
              </div>

              <div class="d-flex">
                <span class="grey--text font-25">{{ $t("weight") }} :</span>
                <span class="font-25 d-block mx-3 primary--text pl-2"
                  >{{ item.weight + $t("kg") }}
                </span>
              </div>
              <div class="">
                <span class="grey--text font-25">{{ $t("category") }} :</span>
                <div class="">
                  <span
                    class="font-15 category_item"
                    v-for="(category, j) in item.categories"
                    :key="j"
                    >{{ category.name }}
                  </span>
                </div>
              </div>
            </v-col>
            <v-col cols="12">
              <div
                class="
                  text-center
                  mt-10
                  mb-14
                  d-flex
                  align-center
                  justify-space-around
                "
              >
                <v-btn
                  class="c-btn mx-3"
                  color="secondary"
                  height="40"
                  @click="openMessageDialog"
                  ><span class="white--text font-300 font-20 d-block px-4">{{
                    $t("sendMessage")
                  }}</span></v-btn
                >
                <v-btn
                  class="c-btn mx-3"
                  color="secondary"
                  height="40"
                  @click="$router.push(`/trip/${item.id}/request-shipments`)"
                  ><span class="white--text font-300 font-20 px-4 d-block">{{
                    $t("requestShipment")
                  }}</span></v-btn
                >
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- buttons -->
    </div>
    <!-- message dialog -->
    <v-dialog v-model="messageDialog" max-width="400" content-class="white">
      <message-dialog
        @close="messageDialog = false"
        :user="item"
        :key="messageDialog"
      ></message-dialog>
    </v-dialog>
  </div>
</template>

<script>
import MessageDialog from "../../../components/dialogs/Message.vue";
export default {
  components: { MessageDialog },
  data: () => ({
    rating: 4,
    item: {},
    messageDialog: false,
  }),
  watch: {
    $route: {
      async handler() {
        await this.getData();
      },
    },
  },
  methods: {
    openMessageDialog() {
      this.messageDialog = true;
    },
    async getData() {
      let { data } = await this.$axios.get("/trip/" + this.$route.params.id);
      if (data.data) {
        this.item = data.data.trip;
        if (localStorage.getItem("userID") == this.item.owner_id) {
          this.$router.replace("/trip/details/" + this.item.id);
        }
      }
    },
  },
  async created() {
    await this.getData();
  },
};
</script>

<style lang="scss" >
.details_trips {
  .category_item {
    border-radius: 5px;
    border: 1px solid #66656533;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>



